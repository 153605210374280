<template>
  <div>
    <RikazGrid name="store" :items="stores">
      <template #card="{ forKey, data: store }">
        <div :key="`store-grid-item-${forKey}`" class="h-auto mb-10">
          <StoresCard
            class="transition-all duration-300 transform hover:scale-105"
            :store="(store as Store)"
          />
        </div>
      </template>
    </RikazGrid>

    <ClientOnly>
      <SelaInfiniteScroll
        :has-initial-cached-materials="stores.length>0"
        class="my-5"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import type { Store } from '~~/composables/useMenuModel'

const props = defineProps({
  itemsPerPage: { type: Number, default: 10 },
  enableCache: { type: Boolean || undefined, default: false },
  fetchRoute: { type: String, default: '/stores' },
  fetchParams: { type: Object, default: () => {} },
  withQueryParams: { type: Boolean, default: false }
})

const { cacheStores, getStores } = useCacheSession()

const stores = ref<Store[]>(props.enableCache ? [...getStores] : [])
const toast = useToast()
const route = useRoute()
const { t } = useI18n()
const { infiniteId, scrollFetch, loadMore, reset } = useInfiniteScroll(
  stores.value.length,
  props.itemsPerPage,
  fetchStores,
  appendStores,
  clearStores
)
if (!stores.value.length) {
  await scrollFetch()
}

if (props.withQueryParams) {
  watch(
    () => route.query,
    () => reset()
  )
}

async function fetchStores(state: ScrollState) {
  let queryParams = {}
  if (props.withQueryParams) {
    queryParams = { ...route.query }
  }

  try {
    const { data, error } = await useApiFetch<ApiResponse<[]>>(
      props.fetchRoute,
      {
        query: {
          offset: `${state.offset}`,
          limit: `${state.limit}`,
          ...queryParams,
          ...props.fetchParams
        }
      }
    )

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('stores') }), { timeout: 1000 })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('stores') })
      } as ApiResponse<[]>
    }
  }
}

function appendStores(data: []) {
  stores.value = [...stores.value, ...data]
  if (data.length && props.enableCache) {
    cacheStores(data)
  }
}

function clearStores() {
  stores.value = []
}
</script>
